import "./template/style.css";
import "./template/mobile.css";
import modalError from "./template/modal-error.html";
import modalOver from "./template/modal-over.html";
import modalSuccess from "./template/modal-success.html";
import homeTemplate from "./template/home.html";
import footerTemplate from "./template/footer.html";
import $ from "jquery";
//import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//FilePond.registerPlugin(FilePondPluginFileValidateType);

var apiPath = "/upload-api"; //Slash at start, no slash at end
var endDate = "2030-12-10 00:00:00";

export default () => {
  $("body").append([
    modalOver,
    modalSuccess,
    modalError,
    homeTemplate,
    footerTemplate
  ]);

  switch (window.location.search) {
    case "?test-error":
      $("#errorMessage").text(
        "Dieser Fehlertext wird variabel vom Programm eingesetzt. Zum Beispiel: Ihre Daten konnten nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
      );
      $("#errorCode").text(
        "Ebenfalls variabel, aber kürzer. Bsp:: Unbekannter Fehler"
      );
      $("#modalError").modal("show");
      return;
    case "?test-success":
      $("#uploadFrom").addClass("d-none");
      $("#modalSuccess").modal("show");
      return;
    case "?test-over":
      $("#uploadFrom").addClass("d-none");
      $("section:not(.keep-over)").hide();
      $("#modalOver").modal("show");
      return;
  }

  if (new Date() > new Date(endDate)) {
    $("section:not(.keep-over)").hide();
    $("#modalOver").modal("show");
    return;
  }

  var pondreturn = null;

  // Turn input element into a pond
  $.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
  $("#fileUpload1").filepond();

  // pond settings for file upload
  $("#fileUpload1").filepond.setOptions({
    labelIdle:
      '<strong>Hier ablegen oder <span class="filepond--label-action"> suchen </span></strong><br><small class="filepond-filetypes">(nur .jpg, .jpeg, .png Dateien erlaubt, max 8 MB möglich)</small>',
    labelFileProcessing: "Wird hochgeladen",
    labelTapToCancel: "zum Abbrechen klicken",
    labelFileProcessingError: "Fehler beim Hochladen",
    labelTapToRetry: "erneut versuchen",
    labelFileProcessingComplete: "Hochladen erfolgreich",
    labelTapToUndo: "zum Entfernen klicken",
    allowFileTypeValidation: true,
    acceptedFileTypes: ["image/jpeg", "image/png"],
    labelFileTypeNotAllowed:
      "Falsches Dateiformat. Nur Bilder vom Typ .jpg oder .png erlaubt.",
    fileValidateTypeLabelExpectedTypes: "",
    allowDrop: false,
    allowReplace: false,
    instantUpload: true,
    server: {
      url: apiPath + "/",
      process: "./process.php"
      //Vielleicht für revert notwendig
      // onremovefile: () => {
      // }
      // process: (
      //   fieldName,
      //   file,
      //   metadata,
      //   load,
      //   error,
      //   progress,
      //   abort,
      //   // eslint-disable-next-line no-unused-vars
      //   transfer,
      //   // eslint-disable-next-line no-unused-vars
      //   options
      // ) => {
      //   // fieldName is the name of the input field
      //   // file is the actual file object to send
      //   const formData = new FormData();
      //   formData.append("filepond", file, file.name);

      //   const request = new XMLHttpRequest();
      //   request.open("POST", apiPath + "/process.php");

      //   // Should call the progress method to update the progress to 100% before calling load
      //   // Setting computable to false switches the loading indicator to infinite mode
      //   request.upload.onprogress = (e) => {
      //     progress(e.lengthComputable, e.loaded, e.total);
      //   };

      //   // Should call the load method when done and pass the returned server file id
      //   // this server file id is then used later on when reverting or restoring a file
      //   // so your server knows which file to return without exposing that info to the client
      //   request.onload = function () {
      //     if (request.status >= 200 && request.status < 300) {
      //       // the load method accepts either a string (id) or an object
      //       load(request.responseText);
      //       $("#receiptId").val(request.responseText);
      //       console.debug(request.responseText);
      //     } else {
      //       // Can call the error method if something is wrong, should exit after
      //       error("oh no");
      //     }
      //   };

      //   request.send(formData);

      //   // Should expose an abort method so the request can be cancelled
      //   return {
      //     abort: () => {
      //       // This function is entered if the user has tapped the cancel button
      //       request.abort();

      //       // Let FilePond know the request has been cancelled
      //       abort();
      //     }
      //   };
      // }
    },
    // onaddfile: (error, file) => {
    //   console.debug("File added: [" + error + "]   file: [" + file.id + "]");
    // },
    onprocessfile: (error, file) => {
      if (error === null) {
        // console.debug(file.serverId);
        pondreturn = JSON.parse(file.serverId);
        if (pondreturn["result"] === "success") {
          $('#uploadFrom button[type="submit"]').removeAttr("disabled");
          $("#submit-disabled").addClass("d-none");
        } else {
          $("#errorCode").text("Unbekannter Fehler nach Upload");
          $("#modalError").modal();
          // console.debug(pondreturn);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onerror: (error, file, status) => {
      $("#errorMessage").text(
        "Die Quittung konnte nicht hochgeladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut"
      );
      if (error.code)
        $("#errorCode").text("Error " + error.code + " - " + error.body);
      else if (error.main) $("#errorCode").text(error.main);
      else {
        $("#errorCode").text("Unbekannter Fehler");
        console.debug("File error", error, "file", file.id, "status", status);
      }

      $("#modalError").modal();
    }
  });

  // submit form
  var form = $("form#uploadFrom");

  form.on("submit", (event) => {
    event.preventDefault();

    if (form[0].checkValidity()) {
      // var formData = new FormData(form);
      var nl = 0;
      if ($("#newsletter")[0].checked) {
        nl = 1;
      }
      var formData = {
        email: $("#email").val(),
        salutation: $("#salutation").val(),
        firstname: $("#firstname").val(),
        lastname: $("#lastname").val(),
        street: $("#street").val(),
        number: $("#number").val(),
        zipcode: $("#zipcode").val(),
        city: $("#city").val(),
        country: $("#country").val(),
        newsletter: nl,
        receiptName: pondreturn["name"]
      };

      $.ajax({
        type: "POST",
        url: apiPath + "/",
        data: formData
      })
        .then((returnedJson) => {
          switch (returnedJson.result) {
            case "error":
              $("#errorMessage").text(
                "Ihre Daten konnten nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
              );
              $("#errorCode").text(returnedJson.error);
              $("#modalError").modal();
              break;
            case undefined:
            case null:
              $("#errorMessage").text(
                "Aufgrund eines Serverproblems konnten Ihre Daten nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
              );
              $("#errorCode").text("Fatal Error");
              $("#modalError").modal();
              // console.debug(returnedJson);
              break;
            default:
              $("#modalSuccess").modal();
              $("#uploadFrom").addClass("d-none");
          }
        })
        .catch((e) => {
          $("#errorMessage").text(
            "Ihre Daten konnten nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
          );
          $("#errorCode").text(e.error);
          $("#modalError").modal();
          // console.error(e);
        });
    } else {
      form[0].classList.add("was-validated");
      event.stopPropagation();
      return false;
    }
  });
};
